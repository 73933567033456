import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '@core/store.state';
import { catchError, exhaustMap, forkJoin, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import {
  emptyAction,
  setAppInitializationComplete,
  setAppInitializationLoad,
} from '@settings-module/store/settings.actions';
import { selectSignalAppId } from '@settings-module/store/settings.selectors';
import { OneSignal } from 'onesignal-ngx';
import { selectClient } from '@client-module/store/client.selectors';
import { PerfilService } from '@services/perfil.service';
import { map } from 'rxjs/operators';
import {
  selectAccountIsActive,
  selectClientToken,
  selectUserDataWithActiveProfileId,
} from '@auth-module/store/auth.selectors';
import { Perfil } from '@models/perfil.model';
import {
  setActiveProfile,
  setContactsRequest,
  setLoadUnreadChats,
  setLoggedClient,
  setProfileList,
  setProfileReservations,
  setSuccessUnreadChats,
} from '@client-module/store/client.actions';
import { ReservaService } from '@services/reserva.service';
import { ContactosService } from '@services/contactos.service';
import { ReservaResponse } from '@models/reserva.model';
import {
  createProfileSuccess,
  deleteProfileSuccess,
  setProfileIsLoading,
  updateProfileSuccess,
} from '../../profile/store/profile.actions';
import { validateCodeSuccess } from '@auth-module/store/auth.actions';
import { LocalStorageService } from '@services/local-storage.service';
import { AccountTypes } from '@helpers/core.helpers';
import { buildStringNameForAction } from '@helpers/shared.helpers';
import { MenuService } from '@core/services/menu.service';
import { SocketsService } from '@services/sockets.service';
import { ChatService } from '@services/chat.service';
import { filter } from 'lodash-es';
import { Mbcliente } from '@models/auth.model';

@Injectable()
export class ClientEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private oneSignal: OneSignal,
    private profileService: PerfilService,
    private reServ: ReservaService,
    private conServ: ContactosService,
    private localStorageService: LocalStorageService,
    private menuService: MenuService,
    private chatService: ChatService,
    private socketService: SocketsService,
  ) {}

  // DOCS: Initial effect to update the client information
  initializePneSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAppInitializationLoad),
      withLatestFrom(this.store.select(selectClient), this.store.select(selectSignalAppId)),
      // DOCS: Initialize the OneSignal SDK
      exhaustMap(([, client, appId]) => {
        // DOCS: Si no esta definido el cliente significa que no esta antenticado.
        if (!client) {
          return of(emptyAction());
        }
        this.oneSignal
          .init({
            appId,
          })
          .then(() => {
            this.oneSignal.on('subscriptionChange', (isSubscribed) => {
              console.log('subscriptionChange:', isSubscribed);
            });

            this.oneSignal.sendTag('email', client.correo, (tagsSent) => {
              console.log('email', tagsSent);
            });

            this.oneSignal.on('notificationDisplay', (event) => {
              console.log('notificationDisplay', event);
              // TODO: Dispatch action to show notification
              /* this.notificacion = event;
              this.showVentanaEmergente = true;*/
            });
          });
        return of(emptyAction());
      }),
    ),
  );

  // DOCS: Obtiene el perfil activo del cliente logueado
  getActiveProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        updateProfileSuccess,
        deleteProfileSuccess,
        createProfileSuccess,
        validateCodeSuccess,
        setLoggedClient,
      ),
      withLatestFrom(
        this.store.select(selectClient),
        this.store.select(selectClientToken),
        this.store.select(selectAccountIsActive),
      ),
      exhaustMap(([action, client, token, accountIsActive]) => {
        // DOCS: Si no esta definido el cliente significa que no esta antenticado.
        if (!client) {
          /** DOCS: Si viene de la inicialización finaliza la inicialización*/
          this.store.dispatch(setAppInitializationComplete());
          return of(null);
        }
        this.store.dispatch(setProfileIsLoading({ profileIsLoading: true }));
        return this.profileService.leerPerfilActivo(client._id, token).pipe(
          map((resp) => {
            if (resp.data) {
              // DOCS: Perfil activo
              const perfiles: Perfil[] = resp.data.mbclientePorPerfilActivo.mbCliente.listaMbPerfil;
              const showTutorial = this.localStorageService.getItem('showTutorial');
              this.store.dispatch(setActiveProfile({ activeProfile: perfiles[0] }));
              if (
                client &&
                ((client.mbTipoCuenta._id === AccountTypes.INDIVIDUAL &&
                  (!perfiles || perfiles?.length === 0)) ||
                  (client.mbTipoCuenta._id === AccountTypes.ENTERPRISE &&
                    (action.type === buildStringNameForAction('auth', 'Validate code success') ||
                      showTutorial)))
              ) {
                this.localStorageService.setShowTutorial(true);
              }
              // DOCS: Si no ha elegido un plan y ya esta la cuenta activa va a los pasos iniciales
              // if (!client.planSeleccionado && !client.mbTransaccionSuscripcion && accountIsActive) {
              //   this.store.dispatch(setShowInitialSteps({ showInitialSteps: true }));
              //   this.router.navigate(['/profile/welcome']);
              // }else{
              //   //DOCS si ya tiene plan activo el menu
              //   this.menuService.setMenuState(true);
              // }

              // DOCS: Se activa el menú ignorando si tiene un plan seleccionado o no
              /*if (client.planSeleccionado) {
                this.menuService.setMenuState(true);
              }*/
              this.menuService.setMenuState(true);

              return perfiles[0];
            }
            /** DOCS: Si viene de la inicialización finaliza la inicialización*/
            this.store.dispatch(setAppInitializationComplete());
            return null;
          }),
        );
      }),
      withLatestFrom(this.store.select(selectClient), this.store.select(selectClientToken)),
      exhaustMap(([activeProfile, client, token]) => {
        // DOCS: Si no esta definido el cliente significa que no esta antenticado.
        if (!activeProfile) {
          /** DOCS: Si viene de la inicialización finaliza la inicialización*/
          this.store.dispatch(setAppInitializationComplete());
          this.store.dispatch(setProfileIsLoading({ profileIsLoading: false }));
          return of(emptyAction());
        }
        const request: Array<Observable<any>> = [
          this.conServ.solicitudContactos(activeProfile._id, token),
          this.reServ.leerReservasSolicidasProximas(client._id, token),
        ];
        return forkJoin(request).pipe(
          map((resp) => {
            const activeProfileContactRequest: Perfil[] = resp[0].data?.mbperfilSolicitudContactos;
            const activeProfileReservations: ReservaResponse[] =
              resp[1].data?.mbreservaPorClienteSolicitadaProxima;
            this.store.dispatch(setContactsRequest({ activeProfileContactRequest }));
            this.store.dispatch(setProfileReservations({ activeProfileReservations }));
            this.store.dispatch(setProfileIsLoading({ profileIsLoading: false }));
            this.store.dispatch(setLoadUnreadChats());
            /** DOCS: Si viene de la inicialización finaliza la inicialización*/
            this.store.dispatch(setAppInitializationComplete());
            return emptyAction();
          }),
        );
      }),
    ),
  );

  // DOCS: Obtención de la lista de chat que tiene el perfil en sesión para obtener el numero de chats sin leer
  getListOfChatLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setLoadUnreadChats),
      concatLatestFrom(() => [this.store.select(selectUserDataWithActiveProfileId)]),
      switchMap(([action, userData]): Observable<Action> => {
        return this.chatService.getChatList(userData.id, userData.activeProfileId).pipe(
          map((resp) => {
            /* Algo falló*/
            if (!resp) {
              return emptyAction();
            }
            const unreadChats = filter(
              resp.mbhistorialchatConversacionPorClientePerfil,
              (item: Mbcliente) => item.cantidadNoLeido > 0,
            );
            return setSuccessUnreadChats({
              unreadChats: unreadChats.length,
            });
          }),
          catchError((error) => {
            return of(emptyAction());
          }),
        );
      }),
    ),
  );

  // DOCS: Obtiene los perfiles del cliente logueado
  getClientsProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        updateProfileSuccess,
        deleteProfileSuccess,
        createProfileSuccess,
        validateCodeSuccess,
        setLoggedClient,
      ),
      withLatestFrom(this.store.select(selectClient), this.store.select(selectClientToken)),
      exhaustMap(([, client, token]) => {
        // DOCS: Si no esta definido el cliente significa que no esta antenticado.
        if (!client) {
          return of(emptyAction());
        }
        return this.profileService.leerPerfiles(client._id, token).pipe(
          map((resp) => {
            // DOCS: Perfiles del cliente
            return setProfileList({ profileList: resp });
          }),
        );
      }),
    ),
  );
}
