import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AuthService } from '@services/auth.service';
import { ReservaResponse } from '@models/reserva.model';
import { Alert } from '@models/alert.model';
import { AlertsService } from '@services/alerts.service';
import { MenuService } from '@services/menu.service';
import { Perfil } from '@models/perfil.model';
import { TranslocoService } from '@ngneat/transloco';
import { LoadjsonService } from '@services/loadjson.service';
import { WindowResizeService } from '@services/window-resize.service';
import { AuthState } from '@auth-module/models/auth-state.model';
import { lastValueFrom, Subscription, take } from 'rxjs';
import { AppState } from '@core/store.state';
import { select, Store } from '@ngrx/store';
import { selectAuth } from '@auth-module/store/auth.selectors';
import { LocalStorageService } from '@services/local-storage.service';
import { AppStoreKeys } from '@helpers/core.helpers';
import {
  selectActiveProfile,
  selectNotificationsCount,
} from '@client-module/store/client.selectors';
import { selectUnreadChats } from '@chat-module/store/chat.selectors';

declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  private navBarPath = false;
  private isSignedIn = false;
  private updatesAvailable = false;
  notificationsCount = this.store.select(selectNotificationsCount);
  unreadChats = this.store.select(selectUnreadChats);
  menuBottom: boolean;
  reservas: ReservaResponse[] = [];
  msj: Alert;
  suscripcion: any;
  isMenuOpen = true;
  subscription: Subscription;
  activeProfile: Perfil;
  @HostListener('window:resize')
  onResize(): void {
    this.windowResizeService.onResize(window.innerWidth);
  }
  @HostListener('window:beforeunload')
  beforeunloadHandler() {
    this.save2fa();
  }
  constructor(
    private router: Router,
    private updates: SwUpdate,
    private authServ: AuthService,
    private alertServ: AlertsService,
    private menuService: MenuService,
    private translocoService: TranslocoService,
    private translateService: LoadjsonService,
    private windowResizeService: WindowResizeService,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
  ) {
    this.onResize();
    this.alertServ.messaje.subscribe((resp) => {
      this.msj = resp;
    });
  }

  async save2fa() {
    const authState: AuthState = await lastValueFrom(this.store.pipe(select(selectAuth), take(1)));
    this.localStorageService.setItem({
      key: AppStoreKeys.is2faPassed,
      value: authState.is2faPassed,
    });
  }

  async ngOnInit() {
    this.store.select(selectActiveProfile).subscribe((activeProfile: Perfil) => {
      this.activeProfile = activeProfile;
      this.translateService.ln =
        this.activeProfile?.listaInfoIdioma[0]?.idioma || this.translateService.dinLang || 'en';
      this.translocoService.setActiveLang(
        this.activeProfile?.listaInfoIdioma[0]?.idioma || this.translateService.dinLang || 'en',
      );
    });

    this.updates.available.subscribe(() => {
      this.updatesAvailable = true;
    });

    this.authServ.isLogged.subscribe((resp) => {
      this.isSignedIn = resp;
    });

    if (fbq) {
      fbq('track', 'PageView');
    }
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const path = e.url.slice(1).replace(/(\?.*)/, '');
        if (
          path.includes('#') ||
          path.includes('terminos/') ||
          path.includes('trabajos') ||
          path.includes('embajador') ||
          path.includes('login') ||
          path.includes('activar') ||
          path.includes('status') ||
          path.includes('buy') ||
          path.includes('welcome') ||
          path.includes('bienvenido') ||
          path.includes('upgrade') ||
          path.includes('downgrade') ||
          path.includes('step2') ||
          path.includes('step3') ||
          path.includes('step4') ||
          path.includes('account/subscription') ||
          path.includes('profile/setup') ||
          path.includes('profile/finish') ||
          path.includes('perfil/configurar/subscripcion') ||
          path.includes('payments/setup') ||
          path.includes('forgot') ||
          path.includes('payments/create') ||
          path.includes('account/multifactor') ||
          path.includes('register') ||
          path.includes('payments')
        ) {
          this.navBarPath = false;
        } else {
          this.navBarPath = true;
        }
        if (fbq) {
          fbq('track', 'PageView');
        }
      }
    });
    // if(this.isSignedIn) {
    //   this.getRequestsCount();
    // }
    this.menuService.getMenuState().subscribe((resp) => {
      this.isMenuOpen = resp;
    });
  }

  get showNavBar(): boolean {
    return this.isSignedIn && this.navBarPath && this.isMenuOpen && !!this.activeProfile;
  }

  get showUpdatesModal(): boolean {
    return this.updatesAvailable && this.navBarPath;
  }

  dismissNotification(): void {
    this.updatesAvailable = false;
  }

  applyUpdates(): void {
    this.updates.activateUpdate().then(() => {
      this.updatesAvailable = false;
      document.location.reload();
    });
  }

  openMenu() {
    this.menuBottom = !this.menuBottom;
  }

  goToService() {
    this.menuBottom = false;
    this.router.navigate(['/services']);
  }

  goToReserves() {
    this.menuBottom = false;
    this.router.navigate(['/bookings']);
  }

  goToHistorial() {
    this.menuBottom = false;
    this.router.navigate(['/history']);
  }

  goToAdd() {
    this.menuBottom = false;
    this.router.navigate(['/services/add']);
  }
}
