import { User } from '@models/user';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Profile } from '@models/profile';
import { environment } from '../../../environments/environment';
import { EmailService } from './email.service';
import { FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Mbresult } from '@models/auth.model';
import { CardService } from './card.service';
import { AlertsService } from './alerts.service';
import { BaseService } from '@services/base.service';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { AppState } from '../store.state';
import { Store } from '@ngrx/store';
import { setLoggedClient } from '@client-module/store/client.actions';
import { ApiResponse } from '@models/api.models';
import { isEmpty } from 'lodash-es';
import { selectClientToken } from '@auth-module/store/auth.selectors';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  private loggedIn$ = new BehaviorSubject<boolean>(false);
  public user$: Observable<User>;
  cardId: string;
  userId: string;
  token = '';

  get isLogged(): Observable<boolean> {
    return this.loggedIn$.asObservable();
  }

  set loggedIn(value: boolean) {
    this.loggedIn$.next(value);
  }
  constructor(
    http: HttpClient,
    public afAuth: AngularFireAuth,
    private router: Router,
    private emailServ: EmailService,
    private cardServ: CardService,
    private alertServ: AlertsService,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
  ) {
    super(http);
    this.store.select(selectClientToken).subscribe((token: string) => {
      this.token = token;
    });
  }
  // initClient() {
  //   gapi.load('client', () => {
  //     console.log('loaded client')

  //     // It's OK to expose these credentials, they are client safe.
  //     gapi.client.init({
  //       apiKey: environment.firebase.apiKey,
  //       clientId: '627772291740-6aqtmggl6m2vseiqfrf2o5o9tge3bnet.apps.googleusercontent.com',
  //       discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
  //       scope: 'https://www.googleapis.com/auth/calendar'
  //     })

  //     gapi.client.load('calendar', 'v3', () => console.log('loaded calendar'));

  //   });

  // }
  async loginGoogle(): Promise<boolean> {
    try {
      const provider = new GoogleAuthProvider();
      const { user } = await this.afAuth.signInWithPopup(provider);
      this.autenticarGoogle(user.email, user.uid).subscribe(async (resp) => {
        const mbResult: Mbresult = resp.data.autenticarGoogle;
        if (mbResult.success) {
          localStorage.setItem('user-data', JSON.stringify(mbResult.mbCliente));

          // DOCS: Nueva implementaciòn ngrx
          const client = mbResult.mbCliente;
          this.store.dispatch(setLoggedClient({ client, accountIsActive: true }));
          this.localStorageService.setItem({
            key: 'auth',
            value: {
              token: client.token,
            },
          });
          // DOCS: Finaliza nueva implementaciòn ngrx

          this.loggedIn = true;
          //DOCS: Finaliza implementacion anterior

          //DOCS: SI SE ESCANEO UNA TARJETA SE ASOCIA
          const card = JSON.parse(localStorage.getItem('card'));
          if (card) {
            this.cardServ
              .asociarTarjeta(mbResult.mbCliente._id, card.id, mbResult.mbCliente.token)
              .subscribe((resp) => {
                const mbResult2: Mbresult = resp.data.asociarTarjeta;
                //poner mensaje de que se asocio la tarjeta o de error
                if (mbResult2.success) {
                  this.alertServ.sendMsj('info', 'Card has been associated');
                } else {
                  this.alertServ.sendMsj(
                    'danger',
                    'An error has occurred, please send an email to support@januus.com',
                  );
                }
                localStorage.removeItem('card');
              });
          }

          if (mbResult.mbCliente.llaveFactorAutenticacion) {
            this.router.navigate(['/login/mfa']);
          } else {
            const currentUrl = localStorage.getItem('currentUrl');
            if (currentUrl) {
              //this.router.navigateByUrl(currentUrl);
              localStorage.removeItem('currentUrl');
              window.location.href = currentUrl;
            } else {
              this.router.navigate(['/profile']);
            }
            return true;
          }
        } else {
          return false;
        }
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async registerGoogle(codigoReferido: string = null, partner: string = null): Promise<boolean> {
    try {
      const provider = new GoogleAuthProvider();
      //provider.addScope('https://www.googleapis.com/auth/calendar');
      //console.log(provider);
      const { user } = await this.afAuth.signInWithPopup(provider);
      // this.afAuth.idToken.subscribe(resp=>{
      // la respuesta es el token para el servicio de calendario
      console.log('user: ', user),
        //   console.log(resp)
        // })
        this.autenticarGoogle(
          user.email,
          user.uid,
          codigoReferido,
          partner,
          user.displayName,
        ).subscribe(async (resp) => {
          const mbResult: Mbresult = resp.data.autenticarGoogle;

          if (mbResult.success) {
            localStorage.setItem('user-data', JSON.stringify(mbResult.mbCliente));

            // DOCS: Nueva implementaciòn ngrx
            const client = mbResult.mbCliente;
            this.store.dispatch(setLoggedClient({ client, accountIsActive: true }));
            this.localStorageService.setItem({
              key: 'auth',
              value: {
                token: client.token,
              },
            });

            this.loggedIn = true;
            //DOCS: Finaliza implementacion anterior

            const card = JSON.parse(localStorage.getItem('card'));
            if (card) {
              this.cardServ
                .asociarTarjeta(mbResult.mbCliente._id, card.id, mbResult.mbCliente.token)
                .subscribe((resp) => {
                  const mbResult2: Mbresult = resp.data.asociarTarjeta;
                  //poner mensaje de que se asocio la tarjeta o de error
                  //console.log('asociarTarjeta',mbResult2.message);
                  if (mbResult2.success) {
                    this.alertServ.sendMsj('info', 'Card has been associated');
                  } else {
                    this.alertServ.sendMsj(
                      'danger',
                      'An error has occurred, please send an email to support@januus.com',
                    );
                  }
                  localStorage.removeItem('card');
                });
            }

            if (mbResult.mbCliente.llaveFactorAutenticacion) {
              this.router.navigate(['/login/mfa']);
            } else {
              const currentUrl = localStorage.getItem('currentUrl');
              if (currentUrl) {
                //this.router.navigateByUrl(currentUrl);
                localStorage.removeItem('currentUrl');
                window.location.href = currentUrl;
              } else {
                this.router.navigate(['/profile']);
              }
              return true;
            }
            // const currentUrl=localStorage.getItem('currentUrl');
            // if(currentUrl){
            // localStorage.removeItem('currentUrl');
            // window.location.href=currentUrl;

            // }else{

            // this.router.navigate(['/home']);

            // }
          } else {
            //TODO: enviar alerta de error
            return false;
          }
        });
      //this.updateUserData(user);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async loginFacebook(): Promise<boolean> {
    try {
      const { user } = await this.afAuth.signInWithPopup(new FacebookAuthProvider());
      console.log('facebook', user);
      //this.updateUserData(user);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async resetPassword(email: string): Promise<void> {
    try {
      return this.afAuth.sendPasswordResetEmail(email);
    } catch (error) {
      console.log(error);
    }
  }

  async sendVerificationEmail(): Promise<void> {
    return (await this.afAuth.currentUser).sendEmailVerification();
  }

  async login(email: string, password: string): Promise<boolean> {
    try {
      const { user } = await this.afAuth.signInWithEmailAndPassword(email, password);

      this.updateUserData(user);
      //this.sendEmail(email);//de test luego sacar
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async register(email: string, password: string): Promise<boolean> {
    try {
      //poner let usuario y resolver
      //mejorar
      let user;
      this.afAuth.createUserWithEmailAndPassword(email, password).then((resp) => {
        this.afAuth.signInWithEmailAndPassword(email, password).then((resp) => {
          this.updateUserData(resp.user);
        });

        this.sendEmail(email);
      });

      //mejorar code

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async logout(): Promise<void> {
    try {
      await this.afAuth.signOut();
      this.loggedIn = false;
    } catch (error) {
      console.log(error);
    }
  }
  async sendEmail(email: string) {
    //usar api
    this.emailServ.sendEmail(email).subscribe((resp) => {});
    //   this.afs.collection("mail")
    //     .add({
    //       to: email,
    //       message: {
    //         subject: "Welcome to Januus",
    //         html: `<div class="body" style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; padding: 0px;">

    //     <div style="
    //     margin: auto;
    //     background-color: #FFFFFF;
    //     position: relative;
    //     margin-top: -10px;
    //     padding: 70px 10% 50px 10%;
    //     z-index: 2;">

    //     <img style="width: 250px;" src="https://januus.com/assets/januus.png">

    //         <h1 style="color: #F39200; margin-top: 80px;">Congratulations!</h1>

    //         <h3 style="color: #3d3d3d; font-weight: normal;">Welcome, for more information visit januus.com</h3>

    //         <br>
    //         <hr style="color: #3d3d3d;">
    //         <h4 style="font-style: normal; font-weight: normal; color:red; margin-bottom: 30px;">
    //             <em>Automatic message sent by <a style="font-style: normal;font-weight: normal;"
    //                     href="https://januus.com/home">Januus
    //                 </a>.
    //                 For more information about the januus app  visit januus.com
    //             </em>
    //         </h4>
    //     </div>
    // </div>`,
    //       },
    //     }).then(resp=>{
    // console.log('correo enviado');
    //     }).catch(error=>{
    //       console.log('error al enviar '+ error);
    //     })
  }
  private updateUserData(user: any) {
    //console.log('update' + JSON.stringify(user));
    // const usersRef$ = this.afs.collection('users');
    // usersRef$
    //   .doc(user.uid)
    //   .get()
    //   .subscribe((u) => {
    //     if (u.exists) {
    //       //console.log('navigating to /cuenta...');
    //       if (u.data()['type'] === 'wco') {
    //         localStorage.setItem('type', 'wco');
    //         this.router.navigate(['account']);
    //       } else {
    //         this.router.navigate(['account']);
    //       }
    //     } else {
    //       const intent = localStorage.getItem('scanIntent');
    //       let customerId;
    //       let businessObj: Business;
    //       let typeObj: string;
    //       if (intent) {
    //         const { id, state, customer, business, type } = JSON.parse(intent);
    //         customerId = customer;
    //         businessObj = business;
    //         typeObj = type;
    //         //console.log(businessObj);
    //         if (state === 'inactive') {
    //           this.cardId = id;
    //         }
    //       }
    //       if (!this.cardId) {
    //         // this.showSignInError = true;
    //         this.afAuth.signOut();
    //         setTimeout(() => {
    //           this.router.navigate(['/login/buy']);
    //         }, 1500);
    //       } else {
    //         const createUser = {
    //           id: user.uid,
    //           fullname: user.displayName || null,
    //           phone: user.phoneNumber || null,
    //           cardId: this.cardId,
    //           email: user.email || null,
    //           isActive: true,
    //           profiles: [],
    //           createdAt: serverTimestamp(),
    //           lastUpdatedAt: serverTimestamp(),
    //           customerId: customerId || null,
    //           businnes: businessObj || null,
    //           type: typeObj || 'user',
    //         };
    //         usersRef$
    //           .doc(user.uid)
    //           .set(createUser)
    //           .then(() => {
    //             if (this.cardId) {
    //               this.afs
    //                 .collection('cards')
    //                 .doc(this.cardId)
    //                 .update({
    //                   isActive: true,
    //                   activatedOn:
    //                     serverTimestamp(),
    //                   lastUpdatedAt:
    //                     serverTimestamp(),
    //                 })
    //                 .then(() => {
    //                   if (businessObj && businessObj.mode === 'main') {
    //                     this.buildProfile(businessObj, user).then((resp) => {
    //                       const profileBusiness = resp;
    //                       //console.log('profile: '+profileBusiness);
    //                       this.afs
    //                         .collection('profiles')
    //                         .add(profileBusiness)
    //                         .then((profile) => {
    //                           //actualizo user
    //                           this.updateUserBusinessMain(
    //                             profile.id,
    //                             profileBusiness
    //                           );
    //                           localStorage.removeItem('scanIntent');
    //                           this.router.navigate(['account']);
    //                         });
    //                     });
    //                   } else {
    //                     localStorage.removeItem('scanIntent');
    //                     this.router.navigate(['account']);
    //                   }
    //                 });
    //             } else {
    //               this.router.navigate(['account']);
    //             }
    //           });
    //       }
    //     }
    //   });
  }
  private updateUserBusinessMain(profileId: string, profileBusiness: Profile) {
    // this.afs
    //   .collection('users')
    //   .doc(this.userId)
    //   .update({
    //     profiles: arrayUnion({
    //       language: profileBusiness.language || '',
    //       fullname: profileBusiness.fullname || null,
    //       isActive: false,
    //       profileId: profileId,
    //       profession: profileBusiness.profession || null,
    //       photo: profileBusiness.photo || null,
    //     }),
    //   })
    //   .then(() => {
    //     localStorage.setItem('create-page-access', `${Date.now()}`);
    //     this.router.navigate(['cuenta']);
    //     //this.router.navigate(['perfil', id]);
    //   });
  }

  // private buildProfile(businnes: Business, user: any): Promise<Profile> {
  //   let businessGet;
  //   let businessObj: Profile;
  //   //buscar toda la info de la empresa
  //   //para luego recorrer y crear perfiles
  //   //console.log('antes de promesa');
  //   // return new Promise<Profile>((resolve, reject) => {
  //   //   //console.log('antes del get: id: ' + businnes.id);

  //   //   this.afs
  //   //     .collection('business')
  //   //     .doc(businnes.id)
  //   //     .get()
  //   //     .subscribe(
  //   //       (resp) => {
  //   //         //console.log(resp);
  //   //         if (resp.exists) {
  //   //           businessGet = resp.data();
  //   //           businessObj = {
  //   //             ...businessGet,
  //   //             ownerId: user.uid,
  //   //           };
  //   //           //console.log(businessObj);
  //   //           resolve(businessObj);
  //   //         }
  //   //       },
  //   //       (error) => {
  //   //         reject(error);
  //   //         console.log('error:' + error);
  //   //       }
  //   //     );
  //   // });
  // }
  registerUsers(
    nombre: string,
    email: string,
    clave: string,
    codigoReferido: string = null,
    partner: string = null,
  ): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', environment.apiKey);
    const query = `mutation createMbCliente($createMbclienteInput: CreateMbclienteInput!){
      createMbcliente(createMbclienteInput: $createMbclienteInput){
        success
        message
        mbCliente {
          _id
          apellidos
          calificacion
          cantidadNoLeido
          ciudad
          clave
          codigo
          codigoPostal
          codigoReferido
          correo
          direccion
          einNumber
          estadoPais
          fechaCreacionEmpresa
          fechaHoraSuscripcion
          fechaNacimiento
          identificacion
          imagen
          listaMbPerfil {
            _id
            altura
            calificacion
            ciudad
            codigoPostal
            correo
            direccion
            edad
            esPublico
            estadoPais
            experiencia
            fechaNacimiento
            imagen
            latitud
            listaDocumento {
              descripcion
              listaArchivo
              titulo
            }
            listaEnlace
            listaExperienciaLaboral {
              actividad
              cargo
              empresa
              esActual
              fechaDesde
              fechaHasta
            }
            listaInfoIdioma {
              idioma
              profesion
              descripcion
              listaEtiqueta
              mbIndustria{
                _id
                catalogo
              }
            }
            longitud
            nombre
            pais
            peso
            telefono
            tema
            unidadMedidaAltura
            unidadMedidaPeso
            usuario
            verCorreo
            verDireccion
            verAltura
            verCiudad
            verDescripcion
            verEdad
            verPais
            verPeso
            verFoto
            verTelefono
            verWhatsapp
            whatsapp
            zonaHoraria
          }
          llaveFactorAutenticacion
          nombres
          pais
          planSeleccionado
          telefono
          token
          total
          mbTransaccionSuscripcion {
            _id
            transaccion
          }
          mbTipoCuenta{
            _id
            catalogo
          }
          tipoSuscripcion
          zonaHoraria
          tipoMetodoAuthenticate
          usuarioAccesoAuthenticate
          esValidoAuthenticate
          respuestaAuthenticate
          intentoProcesoAuthenticate
        }
      }
    }`;
    const variables = {
      createMbclienteInput: {
        correo: email,
        clave: clave,
        nombres: nombre,
        codigoReferido: codigoReferido,
        desdeDondeRegistro: partner,
      },
    };
    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  validarCodigo(id: string, codigo: string, idioma: string = 'en'): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.token)
      .set('x-api-key', environment.apiKey)
      .set('idioma', idioma);

    const query = `mutation validarCodigoCliente($codigo:String! $id:ID!){
      validarCodigoMbcliente(codigo:$codigo id:$id){
        success
        message
      }
    }`;
    const variables = {
      id,
      codigo,
    };
    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  reenviarCodigo(id: string): Observable<any> {
    var headers = new HttpHeaders().set('x-api-key', environment.apiKey);

    const query = `mutation reenviarCodigo($id:ID!){
  reenviarCodigoMbcliente(id:$id){
    message
    success

  }
}`;
    const variables = {
      id: id,
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }

  autenticar(usuario: string, clave: string): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', environment.apiKey);

    const query = `query mbclienteAutenticar($clave: String! $usuario: String!) {
      mbclienteAutenticar(clave: $clave usuario: $usuario) {
        success
        message
        mbCliente {
          _id
          apellidos
          calificacion
          cantidadNoLeido
          ciudad
          codigo
          codigoPostal
          codigoReferido
          correo
          direccion
          einNumber
          estadoPais
          fechaCreacionEmpresa
          fechaHoraSuscripcion
          fechaNacimiento
          idAccountStripe
          identificacion
          idGoogle
          imagen
          listaMbPerfil {
            _id
            altura
            calificacion
            ciudad
            codigoPostal
            correo
            direccion
            edad
            esActivo
            esPublico
            estadoPais
            experiencia
            nombre
            pais
            tema
          }
          llaveFactorAutenticacion
          nombres
          pais
          planSeleccionado
          puntos
          telefono
          token
          mbTransaccionSuscripcion {
            _id
            transaccion
          }
          tipoSuscripcion
          zonaHoraria
          mbTipoCuenta{
            _id
            catalogo
          }
          tipoMetodoAuthenticate
          usuarioAccesoAuthenticate
          esValidoAuthenticate
          respuestaAuthenticate
          intentoProcesoAuthenticate
          mbRepresentante{
            tipoMetodoAuthenticate
            usuarioAccesoAuthenticate
            esValidoAuthenticate
            respuestaAuthenticate
            intentoProcesoAuthenticate
            inicioProcesoAuthenticate
            _id
            apellidos
            nombres
          }
        }
      }
    }`;
    const variables = {
      usuario,
      clave,
    };

    return this.http.post(this.apiUrl, { query, variables }, { headers }).pipe(
      map((resp: ApiResponse) => {
        if (resp.errors && !isEmpty(resp.errors)) {
          const error = resp.errors[0].message;
          console.error(error);
          throwError(() => error);
          return null;
        }
        return resp.data;
      }),
      catchError(({ error }) => {
        return throwError(() => error?.message);
      }),
    );
  }
  autenticarGoogle(
    email: string,
    idGoogle: string,
    codigoReferido: string = null,
    partner: string = null,
    name: string = null,
  ): Observable<any> {
    var headers = new HttpHeaders().set('x-api-key', environment.apiKey);

    const query = `query autenticarGoogle($createMbclienteInput:CreateMbclienteInput!){
  autenticarGoogle(createMbclienteInput:$createMbclienteInput){
    success
    message
    mbCliente{
          _id
          apellidos
          calificacion
          ciudad
          codigo
          codigoPostal
          codigoReferido
          correo
          direccion
          einNumber
          estadoPais
          fechaCreacionEmpresa
          fechaHoraSuscripcion
          idAccountStripe
          idGoogle
          imagen
          puntos
          listaMbPerfil {
            _id
            altura
            calificacion
            ciudad
            codigoPostal
            correo
            direccion
            edad
            esActivo
            esPublico
            estadoPais
            experiencia
            nombre
            pais
            tema
          }
          llaveFactorAutenticacion
          nombres
          pais
          planSeleccionado
          telefono
          token
          mbTransaccionSuscripcion {
            _id
            transaccion
          }
          tipoSuscripcion
          zonaHoraria
          mbTipoCuenta{
            _id
            catalogo
          }
    }
  }
}`;

    const variables = {
      createMbclienteInput: {
        correo: email,
        idGoogle: idGoogle,
        codigoReferido: codigoReferido,
        desdeDondeRegistro: partner,
        nombres: name,
      },
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }

  reenviarCodigoClave(correo: string): Observable<any> {
    var headers = new HttpHeaders().set('x-api-key', environment.apiKey);

    const query = `mutation reenviarCodigoClave($correo:String!){
 reenviarCodigoClaveMbcliente(correo:$correo){
  message
  success


}
  }`;

    const variables = {
      correo: correo,
    };
    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  validarCodigoClave(correo: string, codigo: string): Observable<any> {
    var headers = new HttpHeaders().set('x-api-key', environment.apiKey);

    const query = `mutation validarCodigoClave($correo:String! $codigo:String!){
    validarCodigoClaveMbcliente(correo:$correo codigo:$codigo){
      success
      message
      mbCliente {
        _id
          apellidos
          calificacion
          ciudad
          clave
          codigo
          codigoPostal
          codigoReferido
          correo
          direccion
          einNumber
          estadoPais
          fechaCreacionEmpresa
          fechaHoraSuscripcion
          fechaNacimiento
          identificacion
          imagen
          listaMbPerfil {
            _id
            altura
            calificacion
            ciudad
            codigoPostal
            correo
            direccion
            edad
            esPublico
            estadoPais
            experiencia
            fechaNacimiento
            imagen
            latitud
            listaDocumento {
              descripcion
              listaArchivo
              titulo
            }
            listaEnlace
            listaExperienciaLaboral {
              actividad
              cargo
              empresa
              esActual
              fechaDesde
              fechaHasta
            }
            listaInfoIdioma {
              idioma
              profesion
              descripcion
              listaEtiqueta
              mbIndustria{
                _id
                catalogo
              }
            }
            longitud
            nombre
            pais
            peso
            telefono
            tema
            unidadMedidaAltura
            unidadMedidaPeso
            usuario
            verCorreo
            verDireccion
            verAltura
            verCiudad
            verDescripcion
            verEdad
            verPais
            verPeso
            verFoto
            verTelefono
            verWhatsapp
            whatsapp
            zonaHoraria
          }
          llaveFactorAutenticacion
          nombres
          pais
          telefono
          token
          total
          mbTransaccionSuscripcion {
            _id
            transaccion
          }
          tipoSuscripcion
          zonaHoraria
          tipoMetodoAuthenticate
          usuarioAccesoAuthenticate
          esValidoAuthenticate
          respuestaAuthenticate
          intentoProcesoAuthenticate
      }
     }
    }`;

    const variables = {
      correo,
      codigo,
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }

  updateClave(clave: string, id: string): Observable<any> {
    var headers = new HttpHeaders().set('x-api-key', environment.apiKey);

    const query = `mutation updateClaveCliente($updateMbclienteInput:UpdateMbclienteInput!){
  updateClaveMbcliente(updateMbclienteInput:$updateMbclienteInput){

    success
    message

  }
}`;

    const variables = {
      updateMbclienteInput: {
        clave: clave,
        id: id,
      },
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  generarFactorAutenticacion(idCliente: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const query = `query mbclienteGenerarFactorAutenticacion($id:ID!){

  mbclienteGenerarFactorAutenticacion(id:$id){
    success
    message
    mbCliente{
      llaveFactorAutenticacion
    }
  }
}`;

    const variables = {
      id: idCliente,
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  validarCodigoMfa(idCliente: string, codigo: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbclienteValidarFactorAutenticacion($codigo:String! $id:ID!){
  mbclienteValidarFactorAutenticacion(codigo:$codigo id:$id){
    success
    message

  }
}`;

    const variables = {
      codigo: codigo,
      id: idCliente,
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  renovarToken(token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const query = `query mbclienteRenovarToken{
      mbclienteRenovarToken{
        success
        message
        mbCliente {
          _id
          apellidos
          calificacion
          ciudad
          codigo
          codigoPostal
          codigoReferido
          correo
          direccion
          einNumber
          estadoPais
          fechaCreacionEmpresa
          fechaHoraSuscripcion
          fechaNacimiento
          cantidadNoLeido
          idAccountStripe
          identificacion
          idGoogle
          imagen
          listaMbPerfil {
            _id
            altura
            calificacion
            ciudad
            codigoPostal
            correo
            direccion
            edad
            esActivo
            esPublico
            estadoPais
            experiencia
            nombre
            pais
            tema
          }
          llaveFactorAutenticacion
          nombres
          pais
          planSeleccionado
          puntos
          telefono
          token
          mbTransaccionSuscripcion {
            _id
            transaccion
          }
          tipoSuscripcion
          zonaHoraria
          mbTipoCuenta{
            _id
            catalogo
          }
          mbRepresentante{
            tipoMetodoAuthenticate
            usuarioAccesoAuthenticate
            esValidoAuthenticate
            respuestaAuthenticate
            intentoProcesoAuthenticate
            inicioProcesoAuthenticate
            _id
            apellidos
            codigo
            correo
            estadoPais
            fechaNacimiento
            idGoogle
            identificacion
            imagen
            mbTipoCuenta {
              _id
            }
            nombres
            pais
          }
          tipoMetodoAuthenticate
          usuarioAccesoAuthenticate
          esValidoAuthenticate
          respuestaAuthenticate
          intentoProcesoAuthenticate
        }
      }
    }`;

    return this.http.post(this.apiUrl, { query }, { headers }).pipe(
      map(({ data }: any) => {
        return data.mbclienteRenovarToken;
      }),
      catchError(({ error }) => {
        return throwError(() => error?.message);
      }),
    );
  }
}
