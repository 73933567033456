import { Store } from '@ngrx/store';
import { LocalStorageService } from '@services/local-storage.service';
import { AppSettingsService } from '@services/app-settings.service';
import { AppStoreKeys } from '@helpers/core.helpers';
import { selectInitializationComplete } from '@settings-module/store/settings.selectors';
import { setAppInitializationComplete } from '@settings-module/store/settings.actions';
import { set2faPassed, updateToken } from '@auth-module/store/auth.actions';
import { AuthState } from '@auth-module/models/auth-state.model';
import { SocketsService } from '@services/sockets.service';

export function appInitializer(
  localStorageService: LocalStorageService,
  appSettingsService: AppSettingsService,
  store: Store,
  socketService: SocketsService,
) {
  return () =>
    new Promise<void>((resolve, reject) => {
      // DOCS: Obtiene el navegador utilizado
      appSettingsService.setCurrentBrowser();

      // DOCS: Guarda el idioma del navegador
      appSettingsService.setCurrentBrowserLanguage();

      // DOCS: Cargar la verison del package.json
      appSettingsService.setVersionApp();

      // DOCS: Inicio la obtención de información sobre configuración de la app
      appSettingsService.setFunctionConfigObserver().subscribe((success: boolean) => {
        if (!success) {
          reject();
        }
        // DOCS: Guarda el ip del usuario
        appSettingsService.setPublicIp().subscribe((success: boolean) => {
          if (!success) {
            reject();
          }

          const packUserSessionData: AuthState = localStorageService.getItem(AppStoreKeys.auth);
          const is2faPassed = localStorageService.getItem(AppStoreKeys.is2faPassed);
          if (packUserSessionData?.token) {
            // DOCS: Si existe la sesión, se actualiza el token
            store.dispatch(updateToken({ auth: packUserSessionData, delay: false }));
            store.dispatch(set2faPassed({ is2faPassed }));
            localStorageService.removeItem(AppStoreKeys.is2faPassed);
            store.select(selectInitializationComplete).subscribe((resp) => {
              if (resp) {
                // DOCS: Inicializamos socket
                socketService.connect();
                resolve();
              }
            });
          } else {
            // DOCS: Si no existe la sesión continuo con el arranque de la app
            store.dispatch(set2faPassed({ is2faPassed: false }));
            localStorageService.removeItem(AppStoreKeys.is2faPassed);
            store.dispatch(setAppInitializationComplete());
            resolve();
          }
        });
      });
    });
}
